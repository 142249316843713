<template>
  <table class="table table--striped">
    <thead>
      <tr>
        <th></th>
        <th>Tnx. Hash</th>
        <th>Wallet Address</th>
        <th>Amount</th>
        <th>Created Time<br />(UTC)</th>
        <th>Updated Time<br />(UTC)</th>
        <th v-if="showError === 1">Note</th>
        <th v-else>Status</th>
        <th v-if="showTnxType">Tnx. Type</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="transaction in transactions" :key="transaction.id" class="link--clone" tag="tr">
        <td>
          <div v-if="transaction.networkType === 1">
            <toko-eth-icon />
          </div>
          <div v-if="transaction.networkType === 2">
            <toko-bsc-icon />
          </div>
          <div v-if="transaction.networkType === 3">
            <toko-btc-icon />
          </div>
        </td>

        <td class="wr-20 clipboard-copy" @click="toTransaction(transaction.id)">
          <p class="clipboard-copy__text">
            <span class="text-ellipsis clipboard-copy__text-inner">{{ transaction.tnxHash }}</span>
            <copy-clipboard @on-copy="doCopy($event)" :external-link="scanTrackerTnxLink(transaction)" />
          </p>
        </td>
        <td @click="toTransaction(transaction.id)" class="wr-20 clipboard-copy">
          <p class="text-ellipsis clipboard-copy__text">
            <span class="clipboard-copy__text-inner">{{ transaction.from }}</span>
            <copy-clipboard @on-copy="doCopy($event)" :external-link="scanTrackerAddressLink(transaction)" />
          </p>
        </td>
        <td
          v-if="transaction.assetSymbol.toUpperCase() === 'USDT' || transaction.assetSymbol.toUpperCase() === 'BTC'"
          @click="toTransaction(transaction.id)"
        >
          {{ transaction.amountShort }}
          <br />
          {{ transaction.assetSymbol }}
        </td>
        <td v-else @click="toTransaction(transaction.id)">
          {{ transaction.amountShort | float_number_with_commas }}
          <br />
          {{ transaction.assetSymbol }}
        </td>
        <td @click="toTransaction(transaction.id)" v-html="getMomentLTSFormatted(transaction.addTime)"></td>
        <td
          @click="toTransaction(transaction.id)"
          v-if="transaction.scanFinishTime && transaction.scanFinishTime > 0"
          v-html="getMomentLTSFormatted(transaction.scanFinishTime)"
        ></td>
        <td @click="toTransaction(transaction.id)" v-else v-html="getMomentLTSFormatted(transaction.addTime)"></td>

        <td v-if="showError === 1" @click="toTransaction(transaction.id)">{{ transaction.error }}</td>
        <td
          v-else
          @click="toTransaction(transaction.id)"
          class="text-uppercase text-bold mw-120"
          :class="transaction.status | format_transaction_status_class"
        >
          {{ transaction.status | format_transaction_status_text }}
        </td>

        <td v-if="showTnxType">
          <span :class="`tag tag--md ${transactionType(transaction) === 'deposit' ? 'tag--warning' : 'tag--success'}`">
            {{ transactionType(transaction) }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import momentltsFormat from '@/mixins/momentlts-format'
import copyClipboard from '@/mixins/copy-clipboard'
import CopyClipboard from '@/components/CopyClipboard'
import TokoBscIcon from '@/ui/toko-icons/TokoBSC'
import TokoEthIcon from '@/ui/toko-icons/TokoETH'
import TokoBtcIcon from '@/ui/toko-icons/TokoBTC'
import { TRANSACTION_REQUEST_TYPE, TNX_NETWORK_TYPES } from '@/utils/key-mapping'

export default {
  name: 'Transactions',

  mixins: [momentltsFormat, copyClipboard],

  props: {
    transactions: {
      type: Array,
      require: true
    },

    isNewTab: {
      type: Boolean,
      default: false
    },

    showTnxType: {
      type: Boolean,
      default: false
    },

    showError: {
      type: Number,
      default: 0
    }
  },

  components: {
    CopyClipboard,
    TokoBscIcon,
    TokoEthIcon,
    TokoBtcIcon
  },

  methods: {
    transactionType(tnx) {
      if (tnx.optionsData) {
        if (tnx.optionsData.staking) {
          if (tnx.optionsData.staking.type === TRANSACTION_REQUEST_TYPE.deposit) {
            return 'deposit'
          }

          if (tnx.optionsData.staking.type === TRANSACTION_REQUEST_TYPE.withdraw) {
            return 'withdraw'
          }
        }

        if (tnx.optionsData.crowdfunding_order) {
          if (tnx.optionsData.crowdfunding_order.type === TRANSACTION_REQUEST_TYPE.deposit) {
            return 'deposit'
          }

          if (tnx.optionsData.crowdfunding_order.type === TRANSACTION_REQUEST_TYPE.withdraw) {
            return 'withdraw'
          }
        }
      }
    },

    toTransaction(id) {
      if (this.isNewTab) {
        this.$router.open({ name: 'Transaction', params: { id }, query: { error: this.showError } })
      } else {
        this.$router.push({ name: 'Transaction', params: { id }, query: { error: this.showError } })
      }
    },

    scanTrackerAddressLink(tnx) {
      let scanLink
      if (tnx.networkType === TNX_NETWORK_TYPES.btc) {
        scanLink = `/blockcypher/${tnx.from}`
      }

      if (tnx.networkType === TNX_NETWORK_TYPES.ethereum) {
        scanLink = `/etherscan-address/${tnx.from}`
      }

      if (tnx.networkType === TNX_NETWORK_TYPES.bsc) {
        scanLink = `/bsc-address/${tnx.from}`
      }

      return scanLink
    },

    scanTrackerTnxLink(tnx) {
      let scanLink
      if (tnx.networkType === TNX_NETWORK_TYPES.btc) {
        scanLink = `/blockcypher/${tnx.tnxHash}`
      }

      if (tnx.networkType === TNX_NETWORK_TYPES.ethereum) {
        scanLink = `/etherscan/${tnx.tnxHash}`
      }

      if (tnx.networkType === TNX_NETWORK_TYPES.bsc) {
        scanLink = `/bsc/${tnx.tnxHash}`
      }

      return scanLink
    }
  }
}
</script>
